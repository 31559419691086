import {gql} from '@apollo/client'

const REMOVE_COUPON_MUTATION = gql`
  mutation REMOVE_COUPON_MUTATION($input: RemoveCouponsInput!) {
    removeCoupons(input: $input) {
      cart {
        total
      }
    }
  }
`

export default REMOVE_COUPON_MUTATION
