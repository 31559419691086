import {useMutation, useQuery} from '@apollo/client'

import {useContext, useState} from 'react'

import * as Sentry from '@sentry/browser'

import {v4 as uuidv4} from 'uuid'

import REMOVE_APPLIED_COUPONS from '@/lib/wordpress/woocommerce/removeCouponMutation'

import {useTranslation} from 'react-i18next'

import Button from '@/components/atoms/Button'

import {AppContext} from '@/components/common/AppContext'

import {getFormattedCart} from '@/functions/woocommerce/functions'

import ADD_TO_CART from '@/lib/wordpress/woocommerce/addToCartMutation'

import CLEAR_CART_MUTATION from '@/lib/wordpress/woocommerce/clearCartMutation'

import {useRouter} from 'next/router'

import {Course} from '@/components/types/course'

import {Cart} from '@/components/types/cart'
import {commerce_events} from '@/lib/analytics'
import GET_CART from '@/lib/wordpress/woocommerce/cartQuery'
import styles from './AddToCart.module.scss'

type AddToCartProps = {
  product: Course
  title: string
  removeIcon?: boolean
  isSmall?: boolean
}

const AddToCart = ({
  product,
  title,
  removeIcon = false,
  isSmall = false
}: AddToCartProps) => {
  const productQryInput = {
    clientMutationId: uuidv4(), // Generate a unique id.
    productId: product?.productId,
    variationId: product?.variationId
  }

  const clearMutationInput = {
    clientMutationId: uuidv4(), // Generate a unique id.
    all: true
  }

  const {t} = useTranslation('programm')

  const {push} = useRouter()

  const {cart, setCart} = useContext(AppContext)

  const [, setRequestError] = useState<null | string>(null)

  const [loading, setLoading] = useState(false)

  const updateCart = (data: {cart: Cart}) => {
    const updatedCart = getFormattedCart(data)
    const checkEmptyCart = updatedCart ? {formatedCart: updatedCart} : {}

    localStorage.setItem('woo-next-cart', JSON.stringify(checkEmptyCart))

    setCart(checkEmptyCart)
  }

  const {data} = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',

    onCompleted: () => {
      updateCart(data)
    }
  })

  const [addToCart, {data: cartData}] = useMutation(ADD_TO_CART, {
    variables: {
      input: productQryInput
    },
    onCompleted: async () => {
      const {addToCart} = cartData
      updateCart(addToCart)
      push('/checkout')
    },
    onError: (error) => {
      if (error) {
        setRequestError(error?.graphQLErrors?.[0]?.message ?? '')
      }
    }
  })

  const [clearCartMutation] = useMutation(CLEAR_CART_MUTATION, {
    variables: {
      input: clearMutationInput
    },
    onError: (error) => {
      if (error) {
        setRequestError(error?.graphQLErrors?.[0]?.message ?? '')
      }
    }
  })

  const handleAddToCartClick = async () => {
    try {
      setLoading(true)
      setRequestError(null)
      if (product) {
        commerce_events('add_to_cart', {
          items: [
            {
              item_name: product.name,
              item_id: product.productId,
              price: Number(
                product.variationPrice
                  ?.replace(/&nbsp;/g, ' ')
                  ?.replace(' €', '')
                  ?.replace(',', '.')
              ),
              item_category:
                product.productCategories.nodes.length > 0
                  ? product.productCategories.nodes[0].name
                  : '',
              quantity: '1',
              item_variant: product?.variationId
            }
          ]
        })
      }
      const promoCode =
        cart?.formatedCart && cart?.formatedCart?.appliedCoupons?.length > 0
          ? cart?.formatedCart.appliedCoupons[0]
          : null
      if (promoCode !== null) {
        const removeCouponMutationInput = {
          codes: promoCode?.code
        }

        await removeCouponMutation({
          variables: {input: {...removeCouponMutationInput}}
        })
      }
      if (cart?.formatedCart && Object.keys(cart?.formatedCart).length > 0)
        await clearCartMutation()
      await addToCart()
      setLoading(false)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const [removeCouponMutation] = useMutation(REMOVE_APPLIED_COUPONS)

  return (
    <td
      className={styles.buttonWrapper}
      onTouchStart={(e) => e.preventDefault()}
    >
      <Button
        disabled={loading || product.variationStockQuantity === null}
        onClick={
          product.variationStockQuantity ? handleAddToCartClick : undefined
        }
        text={product.variationStockQuantity === null ? t('soldOut') : title}
        type="filled"
        icon={removeIcon ? null : 'buttonArrow'}
        iconSize={24}
        isSmall={isSmall}
        isLoading={loading}
        className={styles.button}
      />
    </td>
  )
}

export default AddToCart
