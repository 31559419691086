import {gql} from '@apollo/client'

const GET_CART = gql`
  query GET_CART {
    cart {
      contents {
        nodes {
          key
          product {
            node {
              id
              productId: databaseId
              name
              description
              type
              onSale
              slug
              language {
                code
              }
              translations {
                name
                slug
              }
              allPaDuration {
                nodes {
                  name
                }
              }
              allPaDates {
                nodes {
                  name
                }
              }
              allPaBookingTimeSlot {
                nodes {
                  name
                }
              }
              allPaFormat {
                nodes {
                  name
                }
              }
              ProgramFields {
                targetGroup
                classSize
                partnerInstitution
                diploma
                accreditation
                applicationDeadline
                language
                disabled
                location
              }
              productCategories {
                nodes {
                  name
                }
              }
              image {
                id
                sourceUrl
                srcSet
                altText
                title
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
          variation {
            node {
              id
              variationId: databaseId
              name
              description
              type
              onSale
              price
              regularPrice
              salePrice
              image {
                id
                sourceUrl
                srcSet
                altText
                title
              }
              attributes {
                nodes {
                  id
                  name
                  value
                  valueName
                }
              }
            }
          }
          quantity
          total
          subtotal
          subtotalTax
        }
      }
      appliedCoupons {
        code
        discountAmount
      }
      subtotal
      totalTaxes {
        amount(format: FORMATTED)
      }
      subtotalTax(format: FORMATTED)

      shippingTax
      shippingTotal
      total(format: FORMATTED)
      totalTax
      feeTax
      feeTotal
      discountTax
      discountTotal
    }
  }
`

export default GET_CART
